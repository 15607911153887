exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cgu-tsx": () => import("./../../../src/pages/cgu.tsx" /* webpackChunkName: "component---src-pages-cgu-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-pourquoi-quideos-maitrise-des-couts-d-energie-tsx": () => import("./../../../src/pages/pourquoi-quideos/maitrise-des-couts-d-energie.tsx" /* webpackChunkName: "component---src-pages-pourquoi-quideos-maitrise-des-couts-d-energie-tsx" */),
  "component---src-pages-pourquoi-quideos-optimisations-des-couts-de-matieres-premieres-agricoles-tsx": () => import("./../../../src/pages/pourquoi-quideos/optimisations-des-couts-de-matieres-premieres-agricoles.tsx" /* webpackChunkName: "component---src-pages-pourquoi-quideos-optimisations-des-couts-de-matieres-premieres-agricoles-tsx" */),
  "component---src-pages-pourquoi-quideos-optimisations-des-couts-de-produits-alimentaires-transformes-tsx": () => import("./../../../src/pages/pourquoi-quideos/optimisations-des-couts-de-produits-alimentaires-transformes.tsx" /* webpackChunkName: "component---src-pages-pourquoi-quideos-optimisations-des-couts-de-produits-alimentaires-transformes-tsx" */),
  "component---src-pages-produit-conseil-tsx": () => import("./../../../src/pages/produit/conseil.tsx" /* webpackChunkName: "component---src-pages-produit-conseil-tsx" */),
  "component---src-pages-produit-dashboard-tsx": () => import("./../../../src/pages/produit/dashboard.tsx" /* webpackChunkName: "component---src-pages-produit-dashboard-tsx" */),
  "component---src-pages-produit-produits-d-assurance-tsx": () => import("./../../../src/pages/produit/produits-d-assurance.tsx" /* webpackChunkName: "component---src-pages-produit-produits-d-assurance-tsx" */),
  "component---src-pages-qui-sommes-nous-tsx": () => import("./../../../src/pages/qui-sommes-nous.tsx" /* webpackChunkName: "component---src-pages-qui-sommes-nous-tsx" */),
  "component---src-pages-solutions-marche-des-ailes-de-poulet-tsx": () => import("./../../../src/pages/solutions/marche-des-ailes-de-poulet.tsx" /* webpackChunkName: "component---src-pages-solutions-marche-des-ailes-de-poulet-tsx" */),
  "component---src-pages-solutions-marche-du-cafe-tsx": () => import("./../../../src/pages/solutions/marche-du-cafe.tsx" /* webpackChunkName: "component---src-pages-solutions-marche-du-cafe-tsx" */),
  "component---src-pages-tarifs-tsx": () => import("./../../../src/pages/tarifs.tsx" /* webpackChunkName: "component---src-pages-tarifs-tsx" */),
  "component---src-pages-typegen-tsx": () => import("./../../../src/pages/typegen.tsx" /* webpackChunkName: "component---src-pages-typegen-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/Article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/Blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-category-index-tsx": () => import("./../../../src/templates/Category/index.tsx" /* webpackChunkName: "component---src-templates-category-index-tsx" */)
}

